import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Suerth: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Suerth,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Sürth"
          d="M690,908l22,26c-6.6,7.1-15.2,18.9-24.1,29.5c-9.6,11.5-20.1,22.2-31.4,32.1l-0.1,0.1 c-0.3,0.3-0.6,0.5-0.9,0.7L638,1008l-22-16l18-28l-2-16l11.8-33.4l6.2,1.4l4,14L690,908z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 650.2411 951.7784)"
        >
          Sürth
        </text>
      </g>
    </>
  );
});
